<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'checkbox',
    validator: (value: string) => ['checkbox', 'radio', 'switch'].includes(value)
  },
  label: {
    type: String,
    default: null
  },
  modelValue: {
    type: [Array, String, Number, Boolean],
    default: null
  },
  inputValue: {
    type: [String, Number, Boolean],
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])

// const computedValue = computed({
//   get: () => props.modelValue > 0,
//   set: (value) => {
//     emit('update:modelValue', value)
//   }
// })

const computedValue = computed({
  get: () => {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.includes(props.inputValue)
    }
    return props.modelValue === props.inputValue
  },
  set: (checked: boolean) => {
    let updatedValue: any
    if (Array.isArray(props.modelValue)) {
      if (checked) {
        updatedValue = [...props.modelValue, props.inputValue]
      } else {
        updatedValue = props.modelValue.filter((v) => v !== props.inputValue)
      }
    } else {
      updatedValue = checked ? props.inputValue : false
    }
    emit('update:modelValue', updatedValue)
  }
})

const inputType = computed(() => (props.type === 'radio' ? 'radio' : 'checkbox'))
</script>

<template>
  <label :class="type">
    <input v-model="computedValue" :type="inputType" :name="name" :value="inputValue" />
    <span class="check" />
    <span class="pl-2">{{ label }}</span>
  </label>
</template>
