import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth'
import App from './App.vue'
import router from './router'
//import { JsonEditorPlugin } from 'vue3-ts-jsoneditor'

import { firebaseSetup } from './config/firebase'

import { useMainStore } from '@/stores/main.js'
import { useAuthStore } from '@/stores/authStore.js'
import './css/main.css'

// Initialize Firebase Authentication
const auth = getAuth(firebaseSetup)

async function initializeApp() {
  try {
    await setPersistence(auth, browserLocalPersistence)
    //console.log('Persistence set to LOCAL');

    const app = createApp(App)
    const pinia = createPinia()

    app.use(router)
    app.use(pinia)

    // app.use(JsonEditorPlugin, {
    //   componentName: 'JsonEditor',
    //   options: {}
    // })

    // Init main store
    const mainStore = useMainStore()

    // Init auth store
    const authStore = useAuthStore()

    // Wait for the initial auth state to be determined
    await authStore.initializeAuth()

    // Mount the app
    app.mount('#app')

    // Periodically refresh the token
    setInterval(
      async () => {
        await authStore.refreshToken()
      },
      30 * 60 * 1000
    ) // Refresh every 30 minutes
  } catch (error) {
    console.error('Error initializing app:', error)
  }
}

initializeApp()
