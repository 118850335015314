import { ref, reactive } from 'vue'

interface Notification {
  id: number
  type: 'loading' | 'error' | 'info'
  message: string
}

const notifications = reactive<Notification[]>([])
let nextId = 0

export function useNotification() {
  const addNotification = (type: 'loading' | 'error' | 'info', message: string) => {
    const id = nextId++
    notifications.push({ id, type, message })
    if (type !== 'loading') {
      setTimeout(() => removeNotification(id), 5000) // Auto-remove after 5 seconds
    }
    return id
  }

  const removeNotification = (id: number) => {
    const index = notifications.findIndex((n) => n.id === id)
    if (index !== -1) {
      notifications.splice(index, 1)
    }
  }

  const setLoading = (isLoading: boolean, message: string = 'Loading...') => {
    const existingLoading = notifications.find((n) => n.type === 'loading')
    if (isLoading && !existingLoading) {
      addNotification('loading', message)
    } else if (!isLoading && existingLoading) {
      removeNotification(existingLoading.id)
    }
  }

  return {
    notifications,
    addNotification,
    removeNotification,
    setLoading
  }
}
