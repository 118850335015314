<script setup>
import { onUnmounted} from 'vue'
import { useAuthStore } from '@/stores/authStore.js'
import { RouterView } from 'vue-router'

const authStore = useAuthStore()

const { unsubscribe } = authStore

// Clean up the listener when the component is unmounted
onUnmounted(() => {
  console.log('Component unmounting, cleaning up auth listener')
  if (unsubscribe) unsubscribe()
})
</script>

<template>
  <RouterView />
</template>
