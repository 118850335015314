<script setup lang="ts">
import { useMenu } from '@/composables/useMenu'
import AsideMenu from '@/components/AsideMenu.vue'

const { filteredMenu } = useMenu()

defineProps({
  isAsideMobileExpanded: Boolean,
  isAsideLgActive: Boolean
})

defineEmits([
  'menu-click',
  'aside-lg-close-click',
  'update:is-aside-mobile-expanded',
  'update:is-aside-lg-active'
])
</script>

<template>
  <AsideMenu
    :is-aside-mobile-expanded="isAsideMobileExpanded"
    :is-aside-lg-active="isAsideLgActive"
    :menu="filteredMenu"
    @menu-click="$emit('menu-click', $event)"
    @aside-lg-close-click="$emit('aside-lg-close-click')"
  />
</template>
