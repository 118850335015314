export const GET_USERS = `
  query GetUsers($offset: Int!, $limit: Int!, $filters: JSON, $ordering: String) {
    getUsers(offset: $offset, limit: $limit, filters: $filters, ordering: $ordering) {
      users {
        id
        uid
        writername
        created_at
        last_login
      }
      totalCount
    }
  }
`

export const GET_USERS_ROLES = `
  query GetUsersRoles {
    getUsersRoles {
      user_id
      role_ids
    }
  }
`

export const GET_USERS_LANGUAGES = `
  query GetUsersLanguages {
    getUsersLanguages {
      user_id
      languages {
        language_id
        is_manager
      }
    }
  }
`

export const GET_USER_PERMISSIONS = `
  query GetUserPermissions {
    getUserPermissions {
      name
    }
  }
`

export const CREATE_USER = `
  mutation CreateUser($uid: ID!, $writername: string!) {
    createUser(uid: $uid, writername: $writername) {
        id
        uid
        writername
        created_at
        last_login
    }
  }
`

export const UPDATE_USER = `
  mutation UpdateUser($uid: ID!, $writername: String!, $id: Int!) {
    updateUser(uid: $uid, writername: $writername, id: $id) {
      affectedRows
    }
  }
`

export const UPDATE_USER_LOGIN = `
  mutation UpdateUserLogin($uid: ID!) {
    updateUserLogin(uid: $uid) {
      affectedRows
    }
  }
`

export const UPDATE_USER_ROLES = `
  mutation UpdateUserRoles($input: UserRolesInput) {
    updateUserRoles(input: $input) {
      user_id
      role_ids
    }
  }
`

export const UPDATE_USER_LANGUAGES = `
mutation UpdateUserLanguages($input: UserLanguagesInput) {
  updateUserLanguages(input: $input) {
    user_id
    languages {
      language_id
      is_manager
    }
  }
}
`

export const DELETE_USER = `
  mutation DeleteUser($uid: ID!) {
    deleteUser(uid: $uid) {
      affectedRows
    }
  }
`
