import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'
import Style from '@/views/StyleView.vue'
import Home from '@/views/HomeView.vue'
import Login from '@/views/LoginView.vue'
import Signup from '@/views/SignupView.vue'

const routes = [
  {
    meta: {
      title: 'Select style'
    },
    path: '/',
    name: 'style',
    component: Style
  },
  {
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    path: '/dashboard',
    name: 'dashboard',
    component: Home
  },
  {
    meta: {
      title: 'Profile',
      requiresAuth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    meta: {
      title: 'Signup'
    },
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    meta: {
      title: 'Words Management',
      requiresAuth: true
    },
    path: '/words',
    name: 'Words Manager',
    component: () => import('@/views/words/WordsView.vue')
  },
  {
    meta: {
      title: 'Terms Management',
      requiresAuth: true
    },
    path: '/terms',
    name: 'Terms Manager',
    component: () => import('@/views/terms/TermsView.vue')
  },
  {
    meta: {
      title: 'Figures Management',
      requiresAuth: true
    },
    path: '/figures',
    name: 'Figure Manager',
    component: () => import('@/views/bio/FigureView.vue')
  },
  {
    meta: {
      title: 'Event Management',
      requiresAuth: true
    },
    path: '/events',
    name: 'Event Manager',
    component: () => import('@/views/bio/EventView.vue')
  },
  {
    meta: {
      title: 'Config - Language Management',
      requiresAuth: true
    },
    path: '/config/language',
    name: 'Language Manager',
    component: () => import('@/views/config/LanguageView.vue')
  },
  {
    meta: {
      title: 'Config - Registry Management',
      requiresAuth: true
    },
    path: '/config/registry',
    name: 'Registry Manager',
    component: () => import('@/views/config/RegistryView.vue')
  },
  {
    meta: {
      title: 'Config - User Management',
      requiresAuth: true
    },
    path: '/config/user',
    name: 'User Manager',
    component: () => import('@/views/config/UserView.vue')
  },
  {
    meta: {
      title: 'Config - Role Management',
      requiresAuth: true
    },
    path: '/config/role',
    name: 'Role Manager',
    component: () => import('@/views/config/RoleView.vue')
  },
  {
    meta: {
      title: 'Config - Permission Management',
      requiresAuth: true
    },
    path: '/config/permission',
    name: 'Permission Manager',
    component: () => import('@/views/config/PermissionView.vue')
  },
  {
    meta: {
      title: 'Error'
    },
    path: '/error',
    name: 'error',
    component: () => import('@/views/ErrorView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

// Navigation guard
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (sessionStorage.getItem('debug')) {
    console.log(
      `Navigating to: ${to.path}, requiresAuth: ${to.meta.requiresAuth}, isLoggedIn: ${authStore.isLoggedIn}`
    )
  }
  if (to.matched.some((record) => record.meta.requiresAuth) && !authStore.isLoggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
