// import types
import { MenuItem } from '@/types/viewFieldTypes'

// icons
import {
  mdiAccountCircle,
  mdiMonitor,
  mdiGithub,
  mdiLock,
  mdiAlertCircle,
  mdiSquareEditOutline,
  mdiTable,
  mdiViewList,
  mdiTelevisionGuide,
  mdiResponsive,
  mdiPalette,
  mdiReact,
  mdiTranslate
} from '@mdi/js'

export const menuItems: MenuItem[] = [
  {
    to: '/dashboard',
    icon: mdiMonitor,
    label: 'Dashboard'
  },
  {
    label: 'Words',
    icon: mdiMonitor,
    menu: [
      {
        label: 'Words Manager',
        to: '/words',
        icon: mdiTranslate
      }
    ]
  },
  {
    label: 'Terms',
    icon: mdiMonitor,
    menu: [
      {
        label: 'Terms Manager',
        to: '/terms',
        icon: mdiTranslate
      }
    ]
  },
  {
    label: 'Biography',
    icon: mdiMonitor,
    menu: [
      {
        label: 'Figure',
        to: '/figures',
        icon: mdiTranslate
      },
      {
        label: 'Event',
        to: '/events',
        icon: mdiTranslate
      }
    ]
  },
  {
    label: 'Configuration',
    icon: mdiViewList,
    requiredPermission: 'configuration-read',
    menu: [
      {
        label: 'Language Manager',
        to: '/config/language',
        requiredPermission: 'config_language-read',
        icon: mdiTranslate
      },
      {
        label: 'Registry Manager',
        to: '/config/registry',
        requiredPermission: 'config_registry-read',
        icon: mdiTranslate
      },
      {
        label: 'User Manager',
        to: '/config/user',
        requiredPermission: 'config_user-read',
        icon: mdiTranslate
      },
      {
        label: 'Role Manager',
        to: '/config/role',
        requiredPermission: 'config_role-read',
        icon: mdiTranslate
      },
      {
        label: 'Permission Manager',
        to: '/config/permission',
        requiredPermission: 'config_permission-read',
        icon: mdiTranslate
      },
      {
        label: 'Item Two'
      }
    ]
  },
  {
    to: '/login',
    label: 'Login',
    icon: mdiLock
  }
]

// Function to filter menu based on permissions
export function filterMenuByPermissions(
  menu: MenuItem[],
  userCan: (permission: string) => boolean
): MenuItem[] {
  return menu.reduce((acc: MenuItem[], item) => {
    if (item.requiredPermission && !userCan(item.requiredPermission)) {
      if (sessionStorage.getItem('debug')) {
        console.log(`requires ${item.requiredPermission} failed`)
        //console.log(`acc=`, item)
      }
      return acc
    }

    if (item.menu) {
      const filteredSubMenu = filterMenuByPermissions(item.menu, userCan)
      if (filteredSubMenu.length > 0) {
        acc.push({ ...item, menu: filteredSubMenu })
      }
    } else {
      acc.push(item)
    }

    return acc
  }, [])
}
