<script setup>
import { reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { mdiAccount, mdiAsterisk } from '@mdi/js'
import SectionFullScreen from '@/components/SectionFullScreen.vue'
import CardBox from '@/components/CardBox.vue'
import FormCheckRadio from '@/components/FormCheckRadio.vue'
import FormField from '@/components/FormField.vue'
import FormControl from '@/components/FormControl.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseButtons from '@/components/BaseButtons.vue'
import LayoutGuest from '@/layouts/LayoutGuest.vue'
import { useMainStore } from '@/stores/main.js'
import { useAuthStore } from '@/stores/authStore.js'
import { useUserStore } from '@/stores/config/userStore'

const authStore = useAuthStore()
const userStore = useUserStore()

//const isLoggedIn = computed(() => authStore.isLoggedIn)

//const username = computed(() => isLoggedIn.value ? authStore.user.displayName : '')

const form = reactive({
  login: '',
  pass: '',
  remember: true
})

const router = useRouter()

const submit = async () => {
  const uid = await authStore.login(form.login, form.pass)

  if (uid && authStore.isLoggedIn) {
    await userStore.queryUserPermissions()

    const payload = {
      name: authStore.user.displayName ? authStore.user.displayName : authStore.user.email,
      email: authStore.user.email
    }
    useMainStore().setUser(payload)

    // handle the user's last login
    await userStore.updateUserLogin(uid)

    // Store permissions in sessionStorage
    sessionStorage.setItem('permissions', JSON.stringify(userStore.userPermissions))

    router.push('/dashboard')
  } else {
    alert('Wrong credential')
  }
}

watch(
  () => userStore.userPermissions,
  (newValue) => sessionStorage.getItem('permissions', JSON.stringify(newValue))
)
</script>

<template>
  <LayoutGuest>
    <SectionFullScreen v-slot="{ cardClass }" bg="purplePink">
      <CardBox :class="cardClass" is-form @submit.prevent="submit">
        <FormField label="Login" help="Please enter your login">
          <FormControl
            v-model="form.login"
            :icon="mdiAccount"
            name="login"
            autocomplete="username"
          />
        </FormField>

        <FormField label="Password" help="Please enter your password">
          <FormControl
            v-model="form.pass"
            :icon="mdiAsterisk"
            type="password"
            name="password"
            autocomplete="current-password"
          />
        </FormField>

        <FormCheckRadio
          v-model="form.remember"
          name="remember"
          label="Remember"
          :input-value="true"
        />

        <template #footer>
          <div class="flex justify-between">
            <BaseButtons>
              <BaseButton type="submit" color="info" label="Login" />
              <BaseButton to="/dashboard" color="info" outline label="Back" />
            </BaseButtons>
            <BaseButtons>
              <BaseButton to="/signup" color="info" outline label="Signup" />
            </BaseButtons>
          </div>
        </template>
      </CardBox>
    </SectionFullScreen>
  </LayoutGuest>
</template>
