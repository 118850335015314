import { ref, computed, watch } from 'vue'
import { menuItems, filterMenuByPermissions } from '@/menuAside'
import { useUserStore } from '@/stores/config/userStore'
import { UserPermission } from '@/types/userTypes'

export function useMenu() {
  const userStore = useUserStore()
  //const isLoading = ref(true)
  const isLoading = computed(() => !userStore.permissionsLoaded)

  const fetchPermissions = async () => {
    if (!userStore.permissionsLoaded) {
      await userStore.queryUserPermissions()
    }
    //await userStore.queryUserPermissions()
    //isLoading.value = false
  }

  fetchPermissions()

  const permissions = computed(() => userStore.userPermissions || [])

  const can = (check: string) =>
    permissions.value.some((item: UserPermission) => item.name === check)

  //const filteredMenu = computed(() => filterMenuByPermissions(menuItems, userStore.can))
  const filteredMenu = computed(() => filterMenuByPermissions(menuItems, can))

  watch(
    () => userStore.userPermissions,
    (newValue) => {
      if (newValue) {
        sessionStorage.setItem('permissions', JSON.stringify(newValue))
      }
    }
  )

  return { filteredMenu, isLoading }
}
