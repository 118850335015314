import axios, { AxiosResponse } from 'axios'
import { useAuthStore } from '@/stores/authStore.js'

export interface GraphQLErrorResponse {
  errors: Array<{
    message: string
    extensions: {
      exception: {
        errno?: number
      }
    }
  }>
}

// Custom error class for GraphQL errors
class GraphQLError extends Error {
  errors: any[]

  constructor(message: string, errors: any[]) {
    super(message)
    this.name = 'GraphQLError'
    this.errors = errors
  }
}

// Ensure the authStore is initialized
const ensureAuthInitialized = async (): Promise<void> => {
  const authStore = useAuthStore()
  if (!authStore.isInitialized) {
    console.log('Waiting for authStore to initialize...')
    await new Promise<void>((resolve) => {
      const unwatch = authStore.$subscribe(() => {
        if (authStore.isInitialized) {
          console.log('authStore is now initialized')
          unwatch()
          resolve()
        }
      })
    })
  }
}

type GraphQLResponse = Record<string, any>

const executeGraphQL = async (query: string, variables?: Record<string, any>) => {
  const debug = !!sessionStorage.getItem('log')

  try {
    const authStore = useAuthStore()

    // Ensure the authStore is initialized

    await ensureAuthInitialized()

    if (!authStore.user) {
      throw new Error('executeGraphQL: User is not authenticated')
    }

    if (debug) {
      console.log('executeGraphQL: query=', query)
      console.log('executeGraphQL: variables=', variables)
    }

    // Get the token from the authStore
    const token = await authStore.getToken()
    const headers = token ? { Authorization: `Bearer ${token}` } : {}
    const response: AxiosResponse<{ data?: GraphQLResponse; errors?: any[] }> = await axios.post(
      (import.meta as any).env.VITE_GRAPHQL_URL,
      { query, variables },
      { headers: { ...headers } }
    )

    // Handle GraphQL errors
    //if (response.data && 'errors' in response.data && Array.isArray(response.data.errors)) {
    if (response.data.errors && response.data.errors.length) {
      throw new GraphQLError(response.data.errors[0].message, response.data.errors)
    }

    // if (
    //   response.data.errors &&
    //   Array.isArray(response.data.errors) &&
    //   response.data.errors.length > 0 &&
    //   response.data.errors[0].extensions &&
    //   response.data.errors[0].extensions.exception &&
    //   'errno' in response.data.errors[0].extensions.exception
    // ) {
    //   const errno = response.data.errors[0].extensions.exception.errno

    //   if (typeof errno !== 'undefined' && typeof errno === 'number') {
    //     switch (errno) {
    //       case 1062:
    //         throw new GraphQLError('Duplicated key exists', response.data.errors)
    //       default:
    //         console.log('executeGraphQL response.data.errors=', response.data.errors)
    //         throw new GraphQLError(response.data.errors[0].message, response.data.errors)
    //     }
    //   }
    // }

    if (debug) {
      console.log('executeGraphQL: response=', response.data.data)
    }
    return response.data.data
  } catch (error) {
    if (debug) console.error('executeGraphQL error=', error)
    throw error
  }
}

export { executeGraphQL, GraphQLError }
